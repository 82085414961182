<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>{{$t('flightdetails.flightdetails')}}</h1>
            <mdb-spinner v-if="isloading && !error" />
            <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
            <mdb-row v-if="!isloading">
              <mdb-col md="8">
                <mdb-card>
                  <mdb-card-header color="grey darken-3">{{$t('flightdetails.generalinfos')}}</mdb-card-header>
                  <mdb-card-body>
                    <mdb-row>
                      <mdb-col md="6">
                        <mdb-tbl sm>
                          <mdb-tbl-body>
                            <tr>
                              <td>{{$t('flightdetails.flightnum')}}</td>
                              <td>{{flightinfos.flightnum}}</td>
                            </tr>
                            <tr>
                              <td>{{$t('flightdetails.aircraft')}}</td>
                              <td>{{flightinfos.aircraft_callsign}}</td>
                            </tr>
                            <tr>
                              <td>{{$t('flightdetails.passengers_seats')}}</td>
                              <td>{{flightinfos.nbpaxes}} / {{flightinfos.nbseats}}</td>
                            </tr>
                            <tr>
                              <td>{{$t('flightdetails.tags')}}</td>
                              <td><mdb-badge pill :style="`background-color: ${tag.color}!important;`" v-for="(tag,index) in flightinfos.tags" v-bind:key="index">{{tag.code}}</mdb-badge></td>
                            </tr>
                            <tr v-if="$store.state.accessmode === 'FULLACCESS'">
                              <td colspan="2"><mdb-switch onLabel="Hidden flight" offLabel="" v-model="flightinfos.hidden" @getValue="toggleHiddenState" /></td>
                            </tr>
                          </mdb-tbl-body>
                        </mdb-tbl>
                      </mdb-col>
                      <mdb-col md="6">
                        <mdb-tbl sm>
                          <mdb-tbl-body>
                            <tr>
                              <td>{{$t('flightdetails.origin')}}</td>
                              <td>{{flightinfos.origin}}</td>
                            </tr>
                            <tr>
                              <td>{{$t('flightdetails.destination')}}</td>
                              <td>{{flightinfos.destination}}</td>
                            </tr>
                            <tr>
                              <td>{{$t('flightdetails.planneddeparture')}}</td>
                              <td>{{formatDate(flightinfos.departuredatetime)}}</td>
                            </tr>
                            <tr>
                              <td>{{$t('flightdetails.plannedarrival')}}</td>
                              <td>{{formatDate(flightinfos.arrivaldatetime)}}</td>
                            </tr>
                          </mdb-tbl-body>
                        </mdb-tbl>
                      </mdb-col>
                    </mdb-row>
                  </mdb-card-body>
                </mdb-card>
                <mdb-card class="mt-4 mb-4" v-if="$store.state.accessmode === 'FULLACCESS' && crewchange && !crewchange.iscleared && crewchange.haschanged">
                  <mdb-card-header color="warning-color-dark">Crew change detected [<strong>{{formatDate(crewchange.changedat)}}</strong>]</mdb-card-header>
                  <mdb-card-body>
                    <div v-for="(elm,index) in Object.keys(crewchange.crewlist)" v-bind:key="index">
                      <h5>{{elm}}</h5>
                      <mdb-tbl sm>
                        <mdb-tbl-body>
                          <tr v-for="(crew,index) in crewchange.crewlist[elm]" v-bind:key="index">
                            <td>{{crew.first_name}} {{crew.last_name}}</td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                    <mdb-btn v-on:click="setCrewChangeAsCleared" class="float-right" outline="success" size="sm" v-if="true">Set as Cleared</mdb-btn>
                    <div v-if="false">
                      <mdb-spinner v-if="airlinechoicecrewupdating" class="float-right" small />
                      <mdb-btn v-on:click="updateCrewInAirlinechoice" class="float-right" outline="success" size="sm" v-else>Update crew in airline choices</mdb-btn>
                    </div>
                  </mdb-card-body>
                </mdb-card>
                <mdb-card class="mt-4 mb-4" v-if="$store.state.accessmode === 'FULLACCESS' && !paxisloading && blacklist.length">
                  <mdb-card-header color="danger-color-dark">BLACKLIST</mdb-card-header>
                  <mdb-card-body>
                    <mdb-tbl sm>
                      <mdb-tbl-body>
                        <tr v-for="(item,index) in blacklist" v-bind:key="index">
                          <td>{{item.firstname}} {{item.middlename}} {{item.lastname}}</td>
                          <td v-if="item.isclearedfromblacklist">
                            <mdb-btn-group>
                              <mdb-dropdown>
                                <mdb-dropdown-toggle outline="success" slot="toggle" size="sm">Pax Cleared!</mdb-dropdown-toggle>
                                <mdb-dropdown-menu outline="success">
                                  <mdb-dropdown-item v-on:click="showBlacklistDetails(item)" color="success" size="sm">Détails</mdb-dropdown-item>
                                </mdb-dropdown-menu>
                              </mdb-dropdown>
                            </mdb-btn-group>
                          </td>
                          <td v-else><mdb-btn outline="danger" size="sm" v-on:click="showBlacklistDetails(item)">Détails</mdb-btn></td>
                        </tr>
                      </mdb-tbl-body>
                    </mdb-tbl>
                  </mdb-card-body>
                </mdb-card>
                <mdb-card class="mt-4 mb-4" v-if="$store.state.accessmode === 'FULLACCESS'">
                  <mdb-card-header color="pink darken-2">{{$t('flightdetails.passengers')}}</mdb-card-header>
                  <mdb-card-body>
                    <h3 v-if="!bookings.length">{{$t('flightdetails.nobookingfound')}}</h3>
                    <mdb-spinner v-if="paxisloading" />
                    <div v-for="(booking, index) in bookings" v-bind:key="index">
                      <h5>Réservation: <strong>{{booking.bookingnumber}}</strong> <mdb-btn outline="pink darken-2" size="sm" v-on:click="$router.push({ name: 'HolasunBookingDetails', params: { pnr: booking.bookingnumber } })">Détails</mdb-btn></h5>
                      <h6 v-if="booking.pnr">PNR: <strong>{{booking.pnr}}</strong></h6>
                      <mdb-tbl sm>
                        <mdb-tbl-body>
                          <tr v-for="(passenger, paxindex) in booking.passengers" v-bind:key="paxindex">
                            <td>{{passenger.firstname}}</td>
                            <td>{{passenger.middlename}} {{passenger.lastname}}</td>
                            <td>{{formatBirthdate(passenger.birthdate)}}</td>
                            <td>
                              <mdb-icon icon="check" color="success" v-if="passenger.pnr" />
                              <mdb-icon icon="exclamation-triangle" color="danger" v-else />
                              Darwin
                              <br />
                              <mdb-icon icon="check" color="success" v-if="passenger.ischeckedin" />
                              <mdb-icon icon="exclamation-triangle" color="danger" v-else />
                              Checked-in
                            </td>
                            <td>Source: {{passenger.agency}}</td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
              <mdb-col md="4">
                <mdb-card v-if="$store.state.accessmode === 'FULLACCESS'" class="mb-4">
                  <mdb-card-header color="green darken-3">Flight status</mdb-card-header>
                  <mdb-card-body v-if="flightstatus">
                    <mdb-tbl sm>
                      <mdb-tbl-body>
                        <tr>
                          <td>Departure delay</td>
                          <td>{{getDelayAsDatetime(flightstatus.departure_delay_min, flightinfos.departuredatetime)}}</td>
                        </tr>
                        <tr>
                          <td>Arrival delay</td>
                          <td>{{getDelayAsDatetime(flightstatus.arrival_delay_min, flightinfos.arrivaldatetime)}}</td>
                        </tr>
                      </mdb-tbl-body>
                    </mdb-tbl>
                    <div v-if="flightstatus.departure_delay_min || flightstatus.arrival_delay_min">
                      <hr />
                      <h4>Reason</h4>
                      <mdb-tbl-body>
                        <tr>
                          <td>Fr: </td>
                          <td>{{flightstatus.delay_reason_fr}}</td>
                        </tr>
                        <tr>
                          <td>En: </td>
                          <td>{{flightstatus.delay_reason_en}}</td>
                        </tr>
                      </mdb-tbl-body>
                    </div>
                    <mdb-btn size="sm" outline="elegant" class="float-right" v-on:click="openDelayModal = true">Modify delay</mdb-btn>
                  </mdb-card-body>
                  <mdb-card-body v-else>
                    <mdb-spinner />
                  </mdb-card-body>
                </mdb-card>
                <mdb-card>
                  <mdb-card-header color="pink darken-4">Reports</mdb-card-header>
                  <mdb-card-body>
                    <mdb-btn outline="elegant" v-on:click="$router.push({ name: 'FlightReportForm', params: { id: $route.params.id }})">Flight report</mdb-btn>
                    <a href="javascript:;" v-if="false">Download the report</a>
                    <mdb-btn outline="elegant" v-on:click="$router.push({ name: 'FlightManifestImportLog', params: { id: $route.params.id }})" v-if="$store.state.accessmode === 'FULLACCESS'">Manifest import log</mdb-btn>
                  </mdb-card-body>
                </mdb-card>
                <mdb-card class="mt-4" v-if="$store.state.accessmode === 'FULLACCESS' && false">
                  <mdb-card-header color="indigo darken-2">Darwin</mdb-card-header>
                  <mdb-card-body v-if="canpushtodarwin">
                    <h3>Push paxes to Darwin</h3>
                    <mdb-alert color="warning" v-if="!canlockscreen">Veuillez utiliser Google Chrome pour éviter la mise en veille de l'ordinateur pendant le transfert des passagers</mdb-alert>
                    <mdb-btn color="primary" v-on:click="startdarwinsync">Démarrer la synchronisation</mdb-btn>
                  </mdb-card-body>
                  <mdb-card-body v-else>
                    <h3 v-if="bookings.length">Passagers à jour</h3>
                    <h3 v-else>Aucun passager pour ce vol</h3>
                  </mdb-card-body>
                </mdb-card>
                <mdb-card class="mt-4" v-if="$store.state.accessmode === 'FULLACCESS'">
                  <mdb-card-header color="grey darken-2">Manifest</mdb-card-header>
                  <mdb-card-body>
                    <mdb-btn color="primary" tag="a"  :href="`/api/flight/download-manifest-airlinechoice?t=${$store.state.token}&id=${$route.params.id}`">Download XSLX for Airline Choice</mdb-btn>
                  </mdb-card-body>
                </mdb-card>
                <mdb-card class="mt-4" v-if="false">
                  <mdb-card-header color="orange darken-2">Reviews</mdb-card-header>
                  <mdb-card-body>
                    <mdb-btn size="sm" outline="orange" v-on:click="gotoCaptureApplication">Capture App.</mdb-btn>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
    <mdb-modal :show="showtransfertwindow" class="text-center">
      <mdb-modal-header :close=false>
        <h4 class="modal-title" id="myModalLabel">Transfert vers Darwin</h4>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-tbl hover>
          <mdb-tbl-head>
            <tr>
              <th>Booking number</th>
              <th>État</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr v-for="(item,index) in bookingnumbertopushtodarwin" v-bind:key="index" :class="item.state">
              <td>{{item.bookingnumber}}</td>
              <td>{{item.state}}</td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </mdb-modal-body>
    </mdb-modal>
    <mdb-modal :show="openDelayModal" @close="openDelayModal = false;loadFlightStatus();" info>
      <mdb-modal-header :close=false color="info">
        <h4 class="modal-title">Update delays</h4>
      </mdb-modal-header>
      <mdb-modal-body v-if="flightstatus">
        <mdb-input v-model="newDepartureTime" label="new departure time" placeholder="HH:MM +0" />
        <mdb-input v-model="newArrivalTime" label="new arrival time" placeholder="HH:MM +0" />
        <mdb-input v-model="flightstatus.departure_delay_min" label="Departure delay (in minutes)" type="number" v-if="false" />
        <mdb-input v-model="flightstatus.arrival_delay_min" label="Arrival delay (in minutes)" type="number" v-if="false" />
        <mdb-input v-model="flightstatus.delay_reason_fr" label="Reason (FR)" />
        <mdb-input v-model="flightstatus.delay_reason_en" label="Reason (EN)" />
      </mdb-modal-body>
      <mdb-modal-footer right>
        <mdb-btn color="info" v-on:click="saveDelay()">Save</mdb-btn>
        <mdb-btn outline="info" @click="openDelayModal = false;loadFlightStatus();">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal :show="!!(backlistentry)" @close="backlistentry = null;" danger size="lg">
      <mdb-modal-header color="danger">
        <h4 class="modal-title">Blacklist entry infos</h4>
      </mdb-modal-header>
      <mdb-modal-body v-if="backlistentry">
        <h4>Passenger infos</h4>
        <mdb-tbl>
          <mdb-tbl-body>
            <tr>
              <td>{{currentpaxforblacklistmodal.firstname}}</td>
              <td>{{currentpaxforblacklistmodal.lastname}}</td>
              <td>{{formatBirthdate(currentpaxforblacklistmodal.birthdate)}}</td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
        <div v-if="!currentpaxforblacklistmodal.isclearedfromblacklist">
          <hr />
          <mdb-btn color="info" v-on:click="setPaxAsCleared(currentpaxforblacklistmodal.id)">Set this pax as cleared</mdb-btn>
        </div>
        <hr />
        <h4>Blacklist infos</h4>
        <mdb-row>
          <mdb-col md="6">
            <mdb-input v-model="backlistentry.firstname" read-only label="First name" />
          </mdb-col>
          <mdb-col md="6">
            <mdb-input v-model="backlistentry.lastname" read-only label="Last name" />
          </mdb-col>
          <mdb-col md="6">
            <mdb-input v-model="backlistentry.birthdate" read-only label="Birthdate" />
          </mdb-col>
          <mdb-col md="6">
            <mdb-input v-model="backlistentry.gender" read-only label="Gender" />
          </mdb-col>
          <mdb-col md="6">
            <mdb-input v-model="backlistentry.nationality" read-only label="Nationality" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col md="6">
            <mdb-input v-model="backlistentry.eventdate" read-only label="Event date" />
          </mdb-col>
          <mdb-col md="6">
            <mdb-input v-model="backlistentry.eventflightnum" read-only label="Event flight" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col md="6">
            <h4>Type</h4>
            <ul>
              <li v-for="(item,index) in backlistentry.entrycodedesc" v-bind:key="index">{{item}}</li>
            </ul>
          </mdb-col>
          <mdb-col md="6">
            <h4>Action</h4>
            <ul>
              <li v-for="(item,index) in backlistentry.actiondesc" v-bind:key="index">{{item}}</li>
            </ul>
          </mdb-col>
        </mdb-row>
        <hr />
        <h4>Notes</h4>
        {{backlistentry.notes}}
        <hr />
        <h4>Reference</h4>
        {{backlistentry.reference}}
      </mdb-modal-body>
      <mdb-modal-footer right>
        <mdb-btn outline="info" @click="backlistentry = null;">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbAlert,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbTbl,
  mdbTblBody,
  mdbTblHead,
  mdbBtn,
  mdbIcon,
  mdbSpinner,
  mdbBadge,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalFooter,
  mdbInput,
  mdbSwitch,
  mdbBtnGroup,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'flightdetails',
  data() {
    return {
      isloading: true,
      flightinfos: null,
      paxisloading: true,
      bookings: [],
      error: null,
      canpushtodarwin: false,
      canlockscreen: false,
      screenlock: null,
      bookingnumbertopushtodarwin: [],
      showtransfertwindow: false,
      flightstatus: null,
      openDelayModal: false,
      backlistentry: null,
      currentpaxforblacklistmodal: null,
      crewchange: null,
      newDepartureTime: '',
      newArrivalTime: '',
      airlinechoicecrewupdating: false,
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbAlert,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbTbl,
    mdbTblBody,
    mdbIcon,
    mdbSpinner,
    mdbBadge,
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalFooter,
    mdbTblHead,
    mdbInput,
    mdbSwitch,
    mdbBtnGroup,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
  },
  mounted() {
    this.loadFlightInfos();
    this.loadFlightStatus();
  },
  computed: {
    blacklist() {
      const toreturn = [];
      for (let i = 0; i < this.bookings.length; i += 1) {
        for (let j = 0; j < this.bookings[i].passengers.length; j += 1) {
          if (this.bookings[i].passengers[j].foundinblacklist) {
            const tmp = this.bookings[i].passengers[j];
            tmp.bookingnumber = this.bookings[i].bookingnumber;
            toreturn.push(tmp);
          }
        }
      }
      return toreturn;
    },
  },
  methods: {
    loadFlightInfos() {
      const self = this;
      const api = new Apicall();
      self.canlockscreen = ('wakeLock' in navigator);
      api.call('POST', 'api/flight/get-flight-abstract', { id: this.$route.params.id }).then((response) => {
        if (response.status === 'OK') {
          self.isloading = false;
          self.flightinfos = response.data;
          self.loadpaxes();
          self.loadcrewchange();
        } else {
          self.error = response.msg;
        }
      });
    },
    toggleHiddenState(state) {
      const api = new Apicall();
      api.call('POST', 'api/flight/toggle-hidden', { id: this.$route.params.id, ishidden: state });
    },
    loadcrewchange() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/flight/get-crew-change-for-flight', { id: this.$route.params.id }).then((response) => {
        if (response.status === 'OK') {
          self.crewchange = response.data;
        } else {
          self.crewchange = null;
        }
      });
    },
    setCrewChangeAsCleared() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/flight/set-crew-change-as-cleared', { id: this.$route.params.id }).then((response) => {
        self.loadcrewchange();
      });
    },
    loadFlightStatus() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/flight/get-status', { id: this.$route.params.id }).then((response) => {
        if (response.status === 'OK') {
          self.flightstatus = response.data;
        } else {
          self.flightstatus = null;
        }
      });
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    formatBirthdate(_thedate) {
      return _thedate.split('T')[0];
      /*
      const thedate = new Date(`${_thedate.split('T')[0]}GMT-4`);
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
       */
    },
    loadpaxes() {
      this.paxisloading = true;
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/flight/get-passengers-for-flight', { id: this.$route.params.id }).then((response) => {
        self.bookings = response.data;
        self.paxisloading = false;
        let foundonepaxtopush = false;
        self.bookings.forEach((item) => {
          if (!item.pnr) {
            foundonepaxtopush = true;
            self.bookingnumbertopushtodarwin.push({ bookingnumber: item.bookingnumber, state: 'PENDING' });
          }
        });
        self.canpushtodarwin = foundonepaxtopush;
      });
    },
    gotoCaptureApplication() {
      this.$router.push({ name: 'reviewCaptureApp', params: { flightid: this.$route.params.id } });
    },
    saveDelay() {
      const self = this;
      const api = new Apicall();
      if (this.newDepartureTime !== '') {
        const originalddt = new Date(this.flightinfos.departuredatetime);
        const newDep = this.newDepartureTime.match(/\d{2}:\d{2}/)[0];
        let newDepDayOffset = this.newDepartureTime.replace(newDep, '').trim();
        if (!newDepDayOffset.length) {
          newDepDayOffset = '+0';
        }
        const newDepDT = new Date(originalddt.getFullYear(), originalddt.getMonth(), originalddt.getDate() + parseInt(newDepDayOffset, 10), parseInt(newDep.split(':')[0], 10), parseInt(newDep.split(':')[1], 10));
        this.flightstatus.departure_delay_min = (newDepDT - originalddt) / 60000;
      } else {
        this.flightstatus.departure_delay_min = '';
      }
      this.newDepartureTime = '';
      if (this.newArrivalTime !== '') {
        const originaladt = new Date(this.flightinfos.arrivaldatetime);
        const newArr = this.newArrivalTime.match(/\d{2}:\d{2}/)[0];
        let newArrDayOffset = this.newArrivalTime.replace(newArr, '').trim();
        if (!newArrDayOffset.length) {
          newArrDayOffset = '+0';
        }
        const newArrDT = new Date(originaladt.getFullYear(), originaladt.getMonth(), originaladt.getDate() + parseInt(newArrDayOffset, 10), parseInt(newArr.split(':')[0], 10), parseInt(newArr.split(':')[1], 10));
        this.flightstatus.arrival_delay_min = (newArrDT - originaladt) / 60000;
      } else {
        this.flightstatus.arrival_delay_min = '';
      }
      this.newArrivalTime = '';
      api.call('POST', 'api/flight/set-status', { id: this.$route.params.id, status: this.flightstatus }).then((response) => {
        self.openDelayModal = false;
        self.loadFlightStatus();
      });
    },
    saveNewAircraft(val) {
      const api = new Apicall();
      api.call('POST', 'api/flight/set-aircraft', { id: this.$route.params.id, aircraftid: val });
    },
    showBlacklistDetails(pax) {
      const api = new Apicall();
      const self = this;
      this.backlistentry = null;
      this.currentpaxforblacklistmodal = pax;
      api.call('POST', 'api/pax/get-blacklist-entry-for-pax', { paxid: pax.id }).then((response) => {
        if (response.status === 'OK') {
          self.backlistentry = response.data;
        } else {
          self.error = response.msg;
        }
      });
    },
    setPaxAsCleared(id) {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/pax/clear-pax-from-blacklist', { paxid: id }).then(() => {
        self.backlistentry = null;
        self.loadpaxes();
      });
    },
    async startdarwinsync() {
      // https://www.educative.io/edpresso/how-to-keep-your-screen-awake-using-javascript
      this.showtransfertwindow = true;
      if (this.canlockscreen) {
        this.screenlock = await navigator.wakeLock.request('screen');
      }
      const api = new Apicall();
      const self = this;
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < this.bookingnumbertopushtodarwin.length; i += 1) {
        self.bookingnumbertopushtodarwin[i].state = 'PROCESSING';
        const data = {
          bookingnumber: self.bookingnumbertopushtodarwin[i].bookingnumber,
          flightid: this.$route.params.id,
        };
        await api.call('POST', 'api/pax/push-to-darwin-by-booking-number', data);
        self.bookingnumbertopushtodarwin[i].state = 'DONE';
      }
      await this.releasescreenlock();
      this.loadFlightInfos();
    },
    getDelayAsDatetime(delay, datetime) {
      if (!delay || delay === 0) {
        return 'On Time';
      }
      return this.formatDate(new Date(new Date(datetime).setMinutes(new Date(datetime).getMinutes() + delay)));
    },
    async releasescreenlock() {
      if (this.canlockscreen) {
        await this.screenlock.release();
      }
      this.showtransfertwindow = false;
    },
    updateCrewInAirlinechoice() {
      this.airlinechoicecrewupdating = true;
      const api = new Apicall();
      const self = this;
      api.call('POST', 'api/flight/update-crew-in-airline-choice', { flightid: this.$route.params.id }).then(() => {
        self.airlinechoicecrewupdating = false;
        self.loadcrewchange();
      });
    },
  },
};
</script>
<style scoped>
tr.PROCESSING{
  background-color: #ffab91;
}
tr.DONE{
  background-color: #c8e6c9;
}
</style>
<style>
.pasdemargin, .pasdemargin form div.select-dropdown{
  margin: 0!important;
}
</style>
